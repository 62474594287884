<template>
  <div>
    <Table
      :data="tableData"
      :columns="tableColumns"
      :expandable.sync="expandableRows"
      rounded
      @row-click="handleRowClick"
    >
      <template #cell-orderReference="{ rowData: { orderReference, missingDocumentNumber } }">
        <div v-if="orderReference" class="text-primary">
          <Button type="link" class="p-0" @click.stop="handleDocumentRefClick(orderReference.id)">
            {{ $t(`document.exports.schema.type.shortName.${orderReference.type}`) }}
            {{ orderReference.documentNumber }}
          </Button>
        </div>
        <div v-else-if="missingDocumentNumber">
          <TruncatedText>
            {{
              $t('reconciliationModal.creditOrdersTable.missingDocument', {
                number: missingDocumentNumber.documentNumber,
              })
            }}
          </TruncatedText>
        </div>
        <span v-else>{{ $t('billing.billingManagement.expandableActivity.balanceOrder') }}</span>
      </template>
      <template #cell-referenceDate="{ rowData: { referenceDate } }">
        {{ formatDate(referenceDate) }}
      </template>
      <template #cell-reconciliationPeriod="{ rowData: { reconciliationPeriod } }">
        <div v-if="sameMonthAsReconciliation(reconciliationPeriod)">
          {{ $t(`reconciliationModal.creditOrdersTable.currentReconciliation`) }}
        </div>
        <div v-else>
          {{ formatDateShort(reconciliationPeriod) }}
        </div>
      </template>
      <template #cell-matchedToBilling="{ rowData: { matchedToBilling } }">
        <div v-if="matchedToBilling">
          <Button type="link" class="text-primary p-0" @click.stop="handleDocumentRefClick(matchedToBilling.id)">
            {{ $t(`document.exports.schema.type.shortName.${matchedToBilling.type}`) }}
            {{ matchedToBilling.documentNumber }}
          </Button>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell-matchedNoVat="{ rowData: { matchedNoVat } }">
        {{ formatMoney(matchedNoVat) }}
      </template>
      <template #cell-details="{ rowData: { details, differencesObj, missingDocumentInstance, missingDocumentation } }">
        <div v-if="missingDocumentation">
          {{ $t('reconciliationModal.billingTable.missingDocumentation') }}
        </div>
        <div v-else-if="missingDocumentInstance">
          {{ $t('reconciliationModal.creditOrdersTable.missingReturnNote') }}
        </div>
        <div v-else-if="details">
          {{ details }}
        </div>
        <div v-else-if="Object.values(differencesObj).some((arr) => arr.length)">
          <div v-if="differencesObj.resultStatus == 'solvedResult'">
            {{ $t('reconciliationModal.billingTable.differenceClosed') }}
          </div>
          <div v-else-if="differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length">
            <div v-if="differencesObj.unsolvedPriceDiff.length && differencesObj.unsolvedQuantityDiff.length">
              {{
                $t('reconciliationModal.billingTable.unresolvedDifferencesPriceAnQuantity', {
                  count: differencesObj.unsolvedPriceDiff.length + differencesObj.unsolvedQuantityDiff.length,
                  amount: formatMoney(
                    differencesObj.unsolvedPriceDiff.reduce((a, b) => a + b, 0) +
                      differencesObj.unsolvedQuantityDiff.reduce((a, b) => a + b, 0)
                  ),
                })
              }}
            </div>
            <div v-else-if="differencesObj.unsolvedPriceDiff.length">
              {{
                $tc(
                  'reconciliationModal.billingTable.unresolvedDifferencesOnlyPrice',
                  differencesObj.unsolvedPriceDiff.length,
                  {
                    count: differencesObj.unsolvedPriceDiff.length,
                    amount: formatMoney(differencesObj.unsolvedPriceDiff.reduce((a, b) => a + b, 0)),
                  }
                )
              }}
            </div>
            <div v-else-if="differencesObj.unsolvedQuantityDiff.length">
              {{
                $tc(
                  'reconciliationModal.billingTable.unresolvedDifferencesOnlyQuantity',
                  differencesObj.unsolvedQuantityDiff.length,
                  {
                    count: differencesObj.unsolvedQuantityDiff.length,
                    amount: formatMoney(differencesObj.unsolvedQuantityDiff.reduce((a, b) => a + b, 0)),
                  }
                )
              }}
            </div>
          </div>
          <div v-else>
            <div v-if="differencesObj.solvedPriceDiff.length && differencesObj.solvedQuantityDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $t('reconciliationModal.billingTable.unresolvedDifferencesPriceAnQuantity', {
                    count: differencesObj.solvedPriceDiff.length + differencesObj.solvedQuantityDiff.length,
                    amount: formatMoney(
                      differencesObj.solvedPriceDiff.reduce((a, b) => a + b, 0) +
                        differencesObj.solvedQuantityDiff.reduce((a, b) => a + b, 0)
                    ),
                  })
                }}
              </p>
            </div>
            <div v-else-if="differencesObj.solvedPriceDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $tc(
                    'reconciliationModal.billingTable.unresolvedDifferencesOnlyPrice',
                    differencesObj.unsolvedPriceDiff.length,
                    {
                      count: differencesObj.solvedPriceDiff.length,
                      amount: formatMoney(differencesObj.solvedPriceDiff.reduce((a, b) => a + b, 0)),
                    }
                  )
                }}
              </p>
            </div>
            <div v-else-if="differencesObj.unsolvedQuantityDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $tc(
                    'reconciliationModal.billingTable.unresolvedDifferencesOnlyQuantity',
                    differencesObj.solvedQuantityDiff.length,
                    {
                      count: differencesObj.solvedQuantityDiff.length,
                      amount: formatMoney(differencesObj.solvedQuantityDiff.reduce((a, b) => a + b, 0)),
                    }
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-0 action-button hover-btn text-typography-primary"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="isAdmin && missingEventId"
              :disabled="!isAdmin"
              :command="ACTIONS.RESOLVE_MISSING_EVENT"
            >
              {{ $t('billing.billingManagement.expandableActivity.actions.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #expandable-content="{ rowData: { itemsTableData }, rowIndex }">
        <ItemsTable :key="rowIndex" :order="itemsTableData" />
      </template>
    </Table>

    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="handleActivityClose" />
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from 'vue';
import { DateTime } from 'luxon';
import { isNil, flatten, reject, isEmpty } from 'ramda';

import { options } from '@/locale/dateConfig';
import { Table, Button, TruncatedText } from '@/modules/core/components';
import { DocumentModal } from '@/modules/documentModal';
import { useUser } from '@/modules/auth';
import { usePatchMissingEvent } from '@/modules/reconciliation';
import { KebabIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useBusinessById } from '@/modules/eventMapModal/compositions';

import ItemsTable from './ItemsTable';
import { BILLING_TABLE_STATUSES } from '../..';

const TABLE_HEADERS = {
  ORDER_REFERENCE: 'orderReference',
  REFERENCE_DATE: 'referenceDate',
  RECONCILIATION_PERIOD: 'reconciliationPeriod',
  MATCHED_TO_BILLING: 'matchedToBilling',
  MATCHED_NO_VAT: 'matchedNoVat',
  DETAILS: 'details',
  ACTIONS: 'actions',
};

const ACTIONS = {
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};

export default {
  components: {
    Table,
    DocumentModal,
    Button,
    TruncatedText,
    KebabIcon,
    ItemsTable,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
  },
  props: {
    billing: { type: Object, required: true },
    creditOrderObjArr: { type: Array, required: true },
    deliveryTaskIds: { type: Array, default: () => [] },
    missingEvent: { type: Array, default: () => [] },
    month: { type: String, default: () => '' },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const businessId = computed(() => props.billing.businessId);
    const { business } = useBusinessById(businessId);

    const { formatToCurrency } = useCurrency();
    const { isAdmin } = useUser();

    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() => {
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'));
      emit('refetch');
    });
    patchMissingEventOnError(() =>
      $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'))
    );

    return {
      formatToCurrency,
      displayedDocumentId: ref(null),
      activity: ref(null),
      expandableRows: ref({}),
      BILLING_TABLE_STATUSES,
      activeActions: ref(null),
      patchMissingEvent,
      business,
      isAdmin,
      ACTIONS,
    };
  },
  computed: {
    tableColumns() {
      const {
        ORDER_REFERENCE,
        REFERENCE_DATE,
        RECONCILIATION_PERIOD,
        MATCHED_TO_BILLING,
        MATCHED_NO_VAT,
        DETAILS,
        ACTIONS,
      } = TABLE_HEADERS;

      return [
        {
          header: this.$t('commons.reference'),
          key: [ORDER_REFERENCE],
          width: '12rem',
        },
        {
          header: this.$t('commons.date'),
          key: [REFERENCE_DATE],
          width: '9rem',
        },
        {
          header: this.$t('reconciliationModal.creditOrdersTable.reconciliationPeriod'),
          key: [RECONCILIATION_PERIOD],
          width: '9rem',
        },
        {
          header: this.$t('reconciliationModal.creditOrdersTable.matchedToBilling'),
          key: [MATCHED_TO_BILLING],
          width: '9rem',
        },
        {
          header: this.$t('reconciliationModal.creditOrdersTable.matchedNoVat'),
          key: [MATCHED_NO_VAT],
          width: '12rem',
        },
        {
          header: this.$t('commons.details'),
          key: [DETAILS],
        },
        {
          header: '',
          key: [ACTIONS],
          width: '2.5rem',
        },
      ];
    },
    tableData() {
      const { ORDER_REFERENCE, REFERENCE_DATE, RECONCILIATION_PERIOD, MATCHED_TO_BILLING, MATCHED_NO_VAT, DETAILS } =
        TABLE_HEADERS;

      const tableData = this.creditOrderObjArr.map(({ order, billing }) => ({
        [ORDER_REFERENCE]: order.source?.document ?? null,
        [REFERENCE_DATE]: order.date ?? null,
        [RECONCILIATION_PERIOD]: order.date ?? null,
        [MATCHED_TO_BILLING]: billing?.source?.document,
        [MATCHED_NO_VAT]: billing ? this.getMatchedAmount(billing, order) : '-',
        [DETAILS]: billing ? '-' : this.getDetails(order),
        orderId: order.id,
        itemsTableData: order,
        expandable: !billing && this.hasUnsolvedDiffs(order.differences),
        expandableCustomClass: 'p-0 bg-light',
        differencesObj: this.getDifferencesSpecification(order.differences),
        missingDocumentation: order.source?.document?.id && this.deliveryTaskIds.includes(order.source.document.id),
      }));

      if (!isEmpty(this.missingEvent)) {
        tableData.push(
          ...this.missingEvent.map((event) => ({
            missingDocumentNumber: { documentNumber: event.reference },
            differencesObj: this.getDifferencesSpecification([]),
            disabledTextColor: true,
            missingDocumentInstance: true,
            missingEventId: event.id,
          }))
        );
      }

      return tableData;
    },
  },
  methods: {
    getMatchedAmount(billing, currOrder) {
      const relatedCredits = (billing?.related ?? []).map(({ orderLinks }) =>
        orderLinks.filter((orderLink) =>
          billing.orderLinks.some(
            (link) =>
              orderLink.order &&
              link.order &&
              orderLink.order.id === link.order.id &&
              currOrder.id === orderLink.order.id
          )
        )
      );
      return Math.abs(reject(isNil)(flatten(relatedCredits)).reduce((total, { amount }) => total + amount, 0)) / 100;
    },
    handleActivityClose() {
      this.activity = null;
      this.$emit('refetch');
    },
    handleRowClick(index) {
      this.tableData[index].orderId ? (this.activity = { id: this.tableData[index].orderId, type: 'order' }) : null;
    },
    sameMonthAsReconciliation(referenceDate) {
      return DateTime.fromJSDate(new Date(referenceDate)).toFormat('yyyy-LL') === this.month;
    },
    handleDocumentRefClick(documentId) {
      this.displayedDocumentId = documentId;
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : '-';
    },
    formatDateShort(date) {
      return date
        ? new Date(date).toLocaleDateString(this.$i18n.locale, {
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC',
          })
        : '';
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    hasUnsolvedDiffs(differences) {
      return differences.some((diff) => {
        if (diff.netPrice) {
          return !diff.netPrice.solved;
        } else if (diff.quantity) {
          return !diff.quantity.solved;
        } else if (diff.discount) {
          return !diff.discount.solved;
        }
      });
    },
    getDetails(order) {
      if (isNil(order.netAmount)) return this.$t('reconciliationModal.billingTable.missingOrderPrice');
      else if (!order.itemsCount)
        return this.$t('reconciliationModal.billingTable.missingDocumentationRestaurantSide', {
          businessName: this.business?.name,
        });
      else return '';
    },
    getDifferencesSpecification(differences) {
      const differencesObj = differences
        ? differences.reduce(
            (prev, curr) => {
              if (curr.netPrice) {
                curr.netPrice.solved
                  ? prev.solvedPriceDiff.push(curr.amount)
                  : prev.unsolvedPriceDiff.push(curr.amount);
              } else if (curr.quantity) {
                curr.quantity.solved
                  ? prev.solvedQuantityDiff.push(curr.quantityAmount)
                  : prev.unsolvedQuantityDiff.push(curr.quantityAmount);
              } else if (curr.discount) {
                curr.discount.solved
                  ? prev.solvedPriceDiff.push(curr.pricingAmount)
                  : prev.unsolvedPriceDiff.push(curr.pricingAmount);
              }
              return prev;
            },
            { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] }
          )
        : { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] };

      let resultStatus = this.BILLING_TABLE_STATUSES.NETURAL_RESULT;

      if (differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length) {
        resultStatus = this.BILLING_TABLE_STATUSES.UNSOLVED_RESULT;
      } else if (differencesObj.solvedPriceDiff.length || differencesObj.solvedQuantityDiff.length) {
        resultStatus = this.BILLING_TABLE_STATUSES.SOLVED_RESULT;
      }

      differencesObj.resultStatus = resultStatus;

      return differencesObj;
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    handleAction(command, missingEventId) {
      switch (command) {
        case ACTIONS.RESOLVE_MISSING_EVENT:
          this.patchMissingEvent({ id: missingEventId, data: { resolved: true } });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}
</style>
