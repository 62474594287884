<template>
  <div>
    <div class="d-flex gap-2 p-4 border-bottom bg-light-gray" :class="{ description: !creditNotes.length }">
      <template v-if="creditNotes.length">
        <NoticeIcon width="16" height="16" class="icon-margin" />
        <p>{{ $t('reconciliationModal.creditNotesTable.unmatchedCredits') }}</p>
      </template>
      <template v-else>
        <CheckCircleIcon width="16" height="16" class="icon-margin" />
        <p>{{ $tc('reconciliationModal.creditNotesTable.notFound') }}</p>
      </template>
    </div>
    <Table
      v-if="creditNotes.length"
      :columns="columns"
      :data="creditNotes"
      rounded
      @row-click="handleRowClick(creditNotes[$event])"
    >
      <template #cell-document="{ rowData: { document } }">
        <Button type="link" class="p-0" @click.stop="$emit('open-document', document.id)">
          <p>
            {{ $t(`document.exports.schema.type.shortName.${document.type}`) }}
            {{ document.documentNumber }}
          </p>
        </Button>
      </template>
      <template #cell-date="{ rowData: { date: billingDate } }">{{ formatDate(billingDate) }}</template>
      <template #cell-netAmount="{ rowData: { netAmount } }">{{ formatMoney(netAmount) }}</template>
      <template #cell-taxAmount="{ rowData: { taxAmount } }">{{ formatMoney(taxAmount) }}</template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">{{ formatMoney(totalAmount) }}</template>
      <template #cell-leftToMatch="{ rowData: { balance } }">{{ formatMoney(balance) }}</template>
      <template #cell-matched="{ rowData: { matchedCount } }">{{
        matchedCount ? $tc('reconciliationModal.creditNotesTable.orders', matchedCount, { count: matchedCount }) : '-'
      }}</template>
    </Table>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';
import { NoticeIcon, CheckCircleIcon } from '@/assets/icons';
import { useUser } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const TABLE_HEADER = {
  DOCUMENT: 'document',
  DATE: 'date',
  NET_AMOUNT: 'netAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  LEFT_TO_MATCH: 'leftToMatch',
  MATCHED: 'matched',
};

export default {
  components: {
    Table,
    Button,
    NoticeIcon,
    CheckCircleIcon,
  },
  props: {
    creditNotes: { type: Array, default: () => [] },
  },
  setup() {
    const { isAdmin } = useUser();
    const { formatCentsToCurrency } = useCurrency();

    return {
      isAdmin,
      formatCentsToCurrency,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.reference'),
          key: TABLE_HEADER.DOCUMENT,
        },
        {
          header: this.$t('commons.date'),
          key: TABLE_HEADER.DATE,
        },
        {
          header: this.$t('commons.netAmount'),
          key: TABLE_HEADER.NET_AMOUNT,
        },
        {
          header: this.$t('commons.vat'),
          key: TABLE_HEADER.TAX_AMOUNT,
        },
        {
          header: this.$t('commons.totalAmount'),
          key: TABLE_HEADER.TOTAL_AMOUNT,
        },
        {
          header: this.$t('reconciliationModal.creditNotesTable.leftToMatch'),
          key: TABLE_HEADER.LEFT_TO_MATCH,
        },
        {
          header: this.$t('reconciliationModal.creditNotesTable.matched'),
          key: TABLE_HEADER.MATCHED,
        },
      ];
    },
  },
  methods: {
    handleRowClick(event) {
      if (this.isAdmin)
        this.$emit('match-credits', {
          month: DateTime.fromISO(event.date).toFormat('yyyy-LL'),
          billingId: event.id,
        });
      else this.$emit('open-event-map', { id: event.id, type: 'billing' });
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    },
    formatDate(isoDate) {
      return isoDate
        ? new Date(isoDate).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' })
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  border-radius: 0px 0px 6px 6px;
  border-bottom: 0px !important;
}

.icon-margin {
  margin-top: 2px;
}
</style>
